.item {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
@media screen and (min-width: 1024px) {
  .item {
    flex-direction: row;
    justify-content: center;
  }
}
.item .left {
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .item .left {
    text-align: right;
    width: 40%;
  }
}
.item .right {
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .item .right {
    text-align: left;
    width: 60%;
    padding: 0px 16px;
  }
}
