:export {
  primary: #003DA5;
}

.spinner__container {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -40%);
}
.spinner .spin-class {
  display: table;
  margin: 0 auto;
}
.spinner .spin-text {
  color: #666666;
  margin-top: 40px;
}
.spinner .card__inner {
  min-height: 0;
  padding: 0;
}
.spinner__transparent {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.ball-spin-fade-loader > div {
  width: 10px;
  height: 10px;
}
