.titulo {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

.fieldset {
  padding: 24px 20px 24px;
  border: 2px solid #DDDDDD;
  margin: 26px 0px;
}
