.boton {
  width: 126px;
  height: fit-content;
  line-height: 16px;
  min-height: 30px;
  padding: 4px 0px 4px 0px;
}

.contenedor {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.izquierda {
  text-align: right;
}

.derecha {
  text-align: left;
  font-weight: bold;
  padding-left: 0px;
}

.textoPrincipal {
  margin: 0 10%;
}
