.mainContenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F9F9F9;
  padding-top: 50px;
}

.textoPrincipalTitulo {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  margin: 0 0 17px 0;
  color: #020F28;
}

.textoPrincipalDescripcion {
  margin: 0 0 20px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #020F28;
}

.cardContenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textoCardTitulo {
  margin: 0 0 16px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #020F28;
}

.textoCardDescripcion {
  margin: 0 0 48px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #020F28;
}

.botonesContenedor {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
}

.botonPrincipal, .botonSecundario {
  margin-top: 48px;
  align-self: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  border: none;
  background-color: #042F62;
  transition: 0.3s all ease-out;
}

.botonSecundario {
  border: 1px solid #042F62;
  color: #042F62;
  background-color: #FFFFFF;
  transition: 0.3s all ease-out;
}
.botonSecundario:hover {
  border: 1px solid #042F62;
}
