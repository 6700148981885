.titulo {
  max-height: 44px;
  max-width: 780px;
  margin-top: 0;
  margin-bottom: 16px;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 44px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
