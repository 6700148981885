.button-menu-navbar {
  width: 76px;
  height: 28px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #003DA5;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.25);
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: right;
}
.button-menu-navbar:hover {
  background-color: #042F62;
}
.button-menu-navbar:disabled {
  background-color: #9AA1AE;
}
