.container {
  display: flex;
  flex-wrap: wrap;
}

.short-card {
  width: 25%;
  margin: 4px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .short-card {
    width: 35%;
  }
}
@media screen and (min-width: 1024px) {
  .short-card {
    width: 30%;
  }
}
@media screen and (min-width: 1440px) {
  .short-card {
    width: 25%;
  }
}
.small {
  font-size: 14px;
  color: #666666;
}

.large {
  font-size: 28px;
}
