.titulo {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
}

.cabezal {
  text-align: center;
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.cardCalendario {
  padding: 8px 0px 0px;
}

.cardCalendarioInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.cardNuevoInner {
  padding: 0px 48px;
}

.botonNuevo {
  margin-top: 4px;
  width: 300px;
  background-color: #042F62;
  line-height: 20px;
}

.botonConfigurarAgenda {
  margin-top: 4px;
  width: 200px;
  height: auto;
  background-color: #042F62;
  line-height: 20px;
}
@media screen and (min-width: 1024px) {
  .botonConfigurarAgenda {
    width: 300px;
  }
}

.recuadroInformativo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 48px;
  gap: 32px;
  width: 80%;
  background: #F9FBFC;
  border: 1px solid #003DA5;
  border-radius: 8px;
}
.recuadroInformativo p {
  margin: 0;
  color: #042F62;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.tituloNuevo {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin: 0px 5px;
}

.referencia {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 5px;
  gap: 8px;
}
.referencia p {
  margin: 0;
}

.referenciaCuadrado {
  width: 7px;
  height: 7px;
  background: #003DA5;
}

.esquinaDia, .esquinaDiaConAgendas {
  width: 10.5px;
  height: 10.5px;
  position: absolute;
  top: 0px;
  left: 24px;
}
.tile {
  position: relative;
}

.errorEliminar {
  color: #B60000;
  margin: 0;
}

.contenedorCargandoReferencia {
  display: flex;
  justify-content: space-between;
}

.contenedorCargando {
  margin-top: 5px;
}
