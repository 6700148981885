.no-padding {
  padding: 0;
}

.p-l-0 {
  padding-left: 0px;
}

.p-r-0 {
  padding-right: 0px;
}

.m-t-0 {
  margin-top: 0px;
}

.m-t-1 {
  margin-top: 16px;
}

.m-b-16px {
  margin-bottom: 16px;
}

.m-l-16px {
  margin-left: 16px;
}

.block {
  display: block;
}

.max-height {
  height: 100% !important;
}

.text-center {
  text-align: center;
}

html {
  height: 100%;
}

#root {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

.main-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  background-color: #F9F9F9;
  padding-bottom: 608px;
}
@media screen and (min-width: 768px) {
  .main-container {
    padding-bottom: 286px;
  }
}
@media screen and (min-width: 1024px) {
  .main-container {
    padding-bottom: 208px;
  }
}

body {
  margin: 0 !important;
  padding: 0;
  min-height: 100%;
  position: relative;
  font-family: "Open Sans", sans-serif;
  background: #F9F9F9;
  color: #222222;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  body {
    font-size: 16px;
    line-height: 28px;
  }
}
body .alt {
  font-size: 14px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  body .alt {
    font-size: 16px;
    line-height: 24px;
  }
}

h1 {
  margin: 1rem 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 40px;
    line-height: 56px;
  }
}
@media screen and (min-width: 1024px) {
  h1 {
    font-size: 40px;
    line-height: 56px;
  }
}

h2 {
  margin: 0;
  font-weight: bold;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  line-height: 28px;
}
@media screen and (min-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (min-width: 1024px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

h3 {
  margin: 16px 0;
  font-weight: bold;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 18px;
}
@media screen and (min-width: 768px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1024px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
}

h4 {
  margin: 16px 0;
  font-weight: bold;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 16px;
}
@media screen and (min-width: 768px) {
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media screen and (min-width: 1024px) {
  h4 {
    font-size: 16px;
    line-height: 18px;
  }
}

.headline {
  font-size: 16px;
  line-height: 28px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 768px) {
  .headline {
    font-size: 21px;
    line-height: 32px;
  }
}
@media screen and (min-width: 1024px) {
  .headline {
    font-size: 21px;
    line-height: 32px;
  }
}

.subheading {
  margin: 8px 0px 0px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 28px;
}
@media screen and (min-width: 768px) {
  .subheading {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0px 0px;
  }
}
@media screen and (min-width: 1024px) {
  .subheading {
    font-size: 18px;
    line-height: 32px;
    margin: 20px 0px 0px;
  }
}

.caption {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .caption {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .caption {
    font-size: 14px;
    line-height: 20px;
  }
}

a {
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 20px;
}
a:link {
  color: #003DA5;
}
a:hover {
  color: #003DA5;
}
a:focus {
  text-decoration: none;
  color: #222222;
}
a:visited {
  color: #042F62;
}

@media screen and (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .hidden-tablet {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none;
  }
}

input {
  display: block;
  width: 100%;
  margin-top: 8px;
  height: 36px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 28px;
  font-family: "Open Sans", sans-serif;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
input:focus {
  border: 2px solid #003DA5;
  border-radius: 2px;
  background-color: #FFFFFF;
  outline-color: #003DA5;
}

.input__search {
  height: 40px;
  margin-top: 0px;
  font-size: 18px;
  line-height: 26px;
}
.input__search:focus-visible {
  outline: #DAAA00 auto 2px !important;
  outline-offset: 1px;
}

.search_content {
  margin-top: 40px;
}

.no-padding {
  padding: 0px !important;
}

.caption-1 {
  margin: 24px 0px;
  color: #222222;
  font-size: 14px;
  line-height: 24px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.no-scroll {
  overflow: hidden;
}

.pagination-container-helper {
  text-align: center;
}

.pagination {
  margin: 0 auto;
  list-style: none;
  padding-top: 24px;
  color: #003DA5;
  cursor: pointer;
  display: inline-block;
}
.pagination .active {
  font-weight: bold;
  color: black;
}
.pagination .disabled {
  color: #666666;
  cursor: auto;
}
.pagination .disabled a:hover {
  text-decoration: none !important;
}
.pagination .page-item {
  padding: 12px;
  float: left;
}
.pagination .page-link:hover {
  text-decoration: underline;
}
.pagination .page-break {
  float: left;
  padding: 12px;
}
.pagination .paginate-button {
  padding: 12px;
  float: left;
}
.pagination .paginate-button a:hover {
  text-decoration: underline;
}
