.contenedor, .contenedorError, .contenedorInfo {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 50px;
  padding: 10px 24px;
  align-items: center;
}
.contenedorInfo {
  background-color: #E9F2FF;
  justify-content: center;
}
.contenedorError {
  background-color: #FBDADA;
  justify-content: flex-start;
}

.card {
  margin: 0px 0px;
  width: 100%;
  max-width: none;
}

.contenedorTextos {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: #4A5568;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.icono, .iconoError, .iconoInfo {
  width: 24px !important;
  height: 24px !important;
}
.iconoInfo {
  fill: #003DA5 !important;
}
.iconoError {
  fill: #B60000 !important;
}

.textoLink {
  color: #003DA5;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-decoration-color: #003DA5;
}
