.button-flat, .button-flat--short-line-height, .button-flat--small {
  padding: 8px 24px;
  height: 40px;
  line-height: 24px;
  border: none;
  color: #003DA5;
  float: left;
  font-weight: bold;
  outline: none;
  background-color: #FFFFFF;
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
}
.button-flat:hover, .button-flat--short-line-height:hover, .button-flat--small:hover {
  background-color: #F3F3F3;
}
.button-flat:active, .button-flat--short-line-height:active, .button-flat--small:active {
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.25);
}
.button-flat--small {
  padding: 6px 12px;
  height: 28px;
  line-height: 16px;
  font-size: 14px;
}
.button-flat--short-line-height {
  line-height: 20px;
}

.button-flat:disabled, .button-flat--small:disabled, .button-flat--short-line-height:disabled,
.button-flat[disabled],
[disabled].button-flat--small,
[disabled].button-flat--short-line-height {
  background-color: #9AA1AE;
  pointer-events: none;
}
