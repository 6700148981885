.modalContenedor {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 30px 20px 30px;
  align-items: center;
  border: 2px solid #DDDDDD;
}

.contenedorCheckboxes {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.titulo {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 47px;
}

.botonesContenedor {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 10px 0;
  align-self: center;
}

.botonSecundario {
  background-color: #FFFFFF;
  border: 1px #004270 solid;
  color: #004270;
  display: flex;
  align-items: center;
}
.botonSecundario:hover {
  background-color: #EEEEEE;
}

.botonPrimario {
  background-color: #003DA5;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}
.botonPrimario:hover {
  background-color: #004270;
}

.textosInfoAgente {
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  line-height: 7px;
  margin: 0 0 20px 0;
  align-self: flex-start;
}

.checkboxMUI {
  color: #003DA5 !important;
}

.tooltipMUI {
  padding: 16px !important;
  min-height: 50px;
  font-size: 12px;
  line-height: 16px;
}
