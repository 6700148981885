.table {
  margin-top: 32px;
  padding: 0px !important;
}
.table__header {
  text-align: left;
  font-weight: bold;
}
.table__item {
  text-align: left;
  margin: 13px 0px;
  word-wrap: break-word;
}
.table__item-action {
  margin: auto;
  margin-right: 16px;
}
.table__item-group {
  border-bottom: 2px solid #DDDDDD;
}
.table__body {
  height: 24px;
}
.table__btn {
  margin-top: 12px;
}

.buttonModify {
  width: 126px;
  height: 30px;
  margin-bottom: 4px;
}

.contenedorSinResultados {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
