.agentes-inicio {
  position: absolute;
  top: 45%;
  width: 100%;
}
.agentes-inicio .title-row {
  margin: 0 auto;
  width: 178px;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.agentes-inicio .brand-text {
  height: auto;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  font-size: 22px;
  color: #003DA5;
}
.agentes-inicio .btn {
  width: 256px;
}
.agentes-inicio .inner-div {
  margin: 0 auto;
}
.agentes-inicio .line-separator {
  float: left;
  line-height: 28px;
  margin: 0 8px;
  vertical-align: middle;
  width: 1px;
  height: 28px;
  border: 1px solid #DDDDDD;
}
.agentes-inicio .text {
  float: left;
  line-height: 28px;
}
.agentes-inicio .img {
  float: left;
  height: 33px;
}
.agentes-inicio .top-row {
  margin-bottom: 24px;
}
.agentes-inicio .button-margin {
  margin-bottom: 16px;
}
.agentes-inicio .help-text-container {
  width: 300px;
  margin: 0 auto;
}
.agentes-inicio .help-text-container .help-text-agente-no-login {
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
}
.agentes-inicio .help-text-container .help-text-agente-no-login a {
  color: #003DA5;
}
