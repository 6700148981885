.formulario {
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.spinner {
  align-self: center;
}

.boton {
  width: 45%;
  align-self: center;
  height: fit-content;
}

.contenedorBotones {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  justify-content: center;
}

.textArea {
  height: 160px;
}

.motivoRechazoObligatorio {
  margin-top: 10px;
  font-size: 14px;
  line-height: 12px;
  color: #B60000;
}
