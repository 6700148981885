.card {
  margin: 16px auto;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  max-width: 960px;
  width: 90%;
}
@media screen and (min-width: 768px) {
  .card {
    margin: 32px auto 32px;
    width: 75%;
  }
}
@media screen and (min-width: 1024px) {
  .card {
    margin: 32px auto 32px;
    width: 960px;
  }
}
.card__inner {
  width: 100%;
  padding: 24px 16px 48px 16px;
  display: table;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .card__inner {
    padding: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .card__inner {
    padding: 40px;
  }
}
