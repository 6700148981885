.radio_button_field_nuevo {
  margin-top: 8px;
  font-size: 12px;
}
.radio_button_field_nuevo__radio_button {
  display: inline-block;
  min-width: 12px;
  width: 12px;
  height: 12px;
  margin: 0 8px 0 0;
}
.radio_button_field_nuevo label {
  display: inline-block;
}
