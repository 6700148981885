.mainContenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F9F9F9;
  padding-top: 50px;
}

.textoPrincipalTitulo {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  margin: 0 0 17px 0;
  color: #020F28;
}

.textoPrincipalDescripcion {
  margin: 0 0 20px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #020F28;
}

.cardContenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textoCardTitulo {
  margin: 0 0 16px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #020F28;
}

.textoCardDescripcion {
  margin: 0 0 48px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #020F28;
}

.inputContainer {
  width: 408px;
  margin-top: 48px;
}

.contenedorSelectoresHora {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
