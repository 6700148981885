.cedula {
  width: 70%;
  align-self: center;
}

.formulario {
  width: 360px;
  align-self: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.spinner {
  align-self: center;
}

.botonInfoDnic {
  width: fit-content;
  align-self: center;
}

.boton {
  width: 166px;
  align-self: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
