.successful-card {
  background-color: #e8ffec;
  border-left: 3px solid #00632e;
  width: 90%;
  max-width: 888px;
  padding: 16px 16px 32px;
  margin: 16px auto 0;
}
@media screen and (min-width: 768px) {
  .successful-card {
    width: 70%;
    padding: 24px 24px 32px;
    margin: 32px auto 0;
  }
}
@media screen and (min-width: 1024px) {
  .successful-card {
    width: 70%;
    padding: 24px 24px 32px;
    margin: 32px auto 0;
  }
}
.successful-card__img {
  display: inline-block;
  vertical-align: top;
  width: 48px;
}
.successful-card__content {
  display: inline-block;
  padding-left: 16px;
  width: calc(100% - 48px);
  margin: 8px 0 0;
}
@media screen and (min-width: 768px) {
  .successful-card__content {
    padding-left: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .successful-card__content {
    padding-left: 24px;
  }
}
.successful-card__content__extra {
  font-size: 12px;
  margin-top: 32px;
}
@media screen and (min-width: 768px) {
  .successful-card__content__extra {
    margin-top: 40px;
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .successful-card__content__extra {
    margin-top: 40px;
    font-size: 14px;
  }
}
.successful-card__content__extra a {
  margin-left: 4px;
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  .successful-card__content__extra a {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .successful-card__content__extra a {
    font-size: 14px;
  }
}
.successful-card__content__extra p {
  margin: 8px 0 0;
}
.successful-card__content__button__link {
  margin-top: 16px;
}
