.mainContenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F9F9F9;
}

.tituloPrincipal {
  margin: 32px 0 10px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  letter-spacing: 0em;
  color: #000000;
}

.textoSeleccionarDia {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.contenedor {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  width: auto !important;
}

.card {
  margin-top: 24px;
  min-height: 500px;
  max-width: none;
  width: 100%;
}

.alertContenedor, .alertContenedorInfo {
  max-width: none;
  width: 100%;
  margin-bottom: 32px;
}
.alertContenedorInfo {
  margin-top: 32px;
}
