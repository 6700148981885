.contenedorPrincipal {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.contenedorTitulos {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.titulo {
  align-self: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 47px;
}

.subtitulo {
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  line-height: 32px;
  font-weight: 400;
}

.contenedorForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contenedorComponentes, .contenedorComponentesTelefono, .contenedorComponentesPais {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-right: 22px;
}
@media screen and (min-width: 1024px) {
  .contenedorComponentesPais {
    margin-left: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .contenedorComponentes .inputDocumento, .contenedorComponentesTelefono .inputDocumento, .contenedorComponentesPais .inputDocumento {
    max-width: 200px;
  }
}

.boton {
  margin-top: 40px;
}

.contenedorInternoTelefono {
  margin-top: 20px;
}

.labelTelefono {
  color: #042F62;
  font-weight: 600;
  font-size: 16px;
}
.labelTelefonoParentesis {
  color: #9AA1AE;
}

.contenedorComponentesTelefono {
  align-self: baseline;
}
@media screen and (min-width: 1024px) {
  .contenedorComponentesTelefono {
    margin-left: 60px;
  }
}

.contenedorNumeroTelefono {
  align-self: flex-start;
}
.contenedorNumeroTelefono input {
  width: 328px !important;
  height: 56px;
  border-radius: 4px;
  padding: 17px 0 17px 16px;
  margin-top: 0px !important;
  border-color: #042F62 !important;
  background: #FFFFFF;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #031435;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .contenedorNumeroTelefono input {
    width: 360px !important;
  }
}
