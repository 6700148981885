.accionRiesgosa {
  height: 24px;
  color: #B60000;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
  background-color: unset;
  border: 0;
}

.boxCedula {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
