.titulo {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

.izquierda {
  text-align: right;
}

.derecha {
  text-align: left;
  font-weight: bold;
  padding-left: 0px;
}

.fieldset {
  padding: 24px 20px 24px;
  border: 2px solid #DDDDDD;
  margin: 26px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
