@import "../../styles/helpers/variables.scss";
@import "../../styles/helpers/mixins.scss";

.contenedor {
    display: flex;
    width: 147px;
    max-height: 316px;
    align-self: center;

    margin: 0;
    min-height: 50px;
    padding: 16px 26px 16px 16px;

    border: 1px solid $grey-9;
    border-radius: 8px;
}

.fakeBar {
    height: auto;
    border: 4px solid $grey-9;
    border-radius: 8px;
}

.horasInternasDiv {
    display: flex;
    width: 100%;
    padding: 0px 10px 0px 0px;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    &.disabled {
        overflow-y: hidden;
    }

    scrollbar-width: thin;
    scrollbar-color: $grey-10 $grey-9;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: $grey-9;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: $grey-10;
        border-radius: 8px;
    }
}

.ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
}

.li {
    margin: 0;
    padding: 0;
}

.boton {
    margin: 4px;
    padding: 9px 10px;
    box-sizing: border-box;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    height: 40px;
    border: 1px solid transparent;
    border-radius: 4px;
    white-space: nowrap;

    cursor: pointer;

    &:disabled {
        color: $grey-9;
    }

    &:hover:enabled {
        background-color: $grey-9;
    }

    &.selected:enabled {
        background-color: $grey-9;
        border: 1.5px solid $blue;
        color: $blue;
    }
}

.contenedorSpinner {
    min-height: 100px;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
