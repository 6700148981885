.box {
  padding-right: 24px;
  padding-left: 20px;
  padding-bottom: 24px;
  max-width: 960px;
  border: 2px solid #DDDDDD;
  margin: 26px 0px;
}

.box__title--container {
  margin-left: 4px;
}

.box__title {
  max-height: 28px;
  color: #222222;
  background-color: #FFFFFF;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  margin: 0 4px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

.box--row {
  margin-top: 16px;
}

.box--row__mail {
  height: 24px;
  min-width: 616px;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0 5px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

span.box--row__span {
  padding-left: 0;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

span.box--row__span_title {
  text-align: right;
}

span.box--row__span_value {
  font-weight: bold;
}
span.box--row__span_value-left {
  font-weight: bold;
  text-align: left;
}

pan.box--row__span_content {
  padding-left: 0px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
}

span.box--row__span_extra {
  padding-left: 15px;
  height: 25px;
  width: 225px;
}

.link-style {
  height: 24px;
  width: 179px;
  color: #003DA5;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.line-separator {
  box-sizing: border-box;
  height: 22px;
  width: 2px;
  border: 2px solid #DDDDDD;
}

.no-padding-left {
  padding-left: 0px;
}

span.sub_box {
  height: 24px;
  padding-left: 0px;
}

.icono {
  height: 18px;
  width: 18px;
  vertical-align: middle;
  margin-left: 7px;
}

.icono-box {
  height: 24px;
  width: 24px;
}
