.alert {
  background-color: #F3F3F3;
  color: #333333;
  border-left: 3px solid #BBBBBB;
  padding: 14px 17px;
  width: 100%;
  margin: 18px 12px 0 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  border-radius: 5px;
}

.alert--warning {
  background-color: #fbf8ea;
  border-color: #ffd15f;
  color: #332b00;
}

.alert--success {
  background-color: #E8FFEC;
  border-color: #279E3F;
  color: #052d0d;
}

.alert--info {
  background-color: #eaf2ff;
  border-color: #2d5ca8;
  color: #052d0d;
  padding: 10px 0px;
  margin-top: 0px;
}

.alert--error {
  background-color: #f5f5f5;
  border-color: #bbb;
  color: #052d0d;
  padding: 10px 0px;
}

.icon {
  float: left;
  margin-right: 16px;
}

.like-link {
  cursor: pointer;
  color: #003DA5;
  text-decoration: underline;
  background-color: unset;
  border: 0;
}
