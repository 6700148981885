.botonesContenedor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 22px;
  text-decoration: none;
  margin-top: 20px;
}

.boton {
  align-self: center;
  height: fit-content;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
