.contenedorSelects {
  display: flex !important;
  flex-direction: row !important;
  gap: 8px !important;
  align-items: flex-end;
}

.label {
  font-family: "Open Sans" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #042F62 !important;
  margin: 0 0 4px 0 !important;
}

.select, .textField, .textFieldExtendido {
  width: 200px !important;
  height: 42px !important;
  margin-top: 0px !important;
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  border-radius: 5px !important;
  color: #042F62 !important;
  background-color: #FFFFFF !important;
  box-shadow: none !important;
  border: 1px solid #35435D !important;
}
.select:hover, .textField:hover, .textFieldExtendido:hover {
  cursor: pointer !important;
}
.select input, .textField input, .textFieldExtendido input {
  padding: 9px !important;
}
.select input:focus, .textField input:focus, .textFieldExtendido input:focus {
  padding: 7px !important;
  border-radius: 5px !important;
}

.MuiMenu-list ul {
  max-height: 300px !important;
}
.MuiMenu-list ul ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.MuiMenu-list ul ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.textField, .textFieldExtendido {
  padding: 0 !important;
}
.textFieldExtendido {
  width: 300px !important;
}

.botonesContenedor {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.botonPrimario {
  background-color: #003DA5;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  height: 42px;
}
.botonPrimario:hover {
  background-color: #004270;
}
