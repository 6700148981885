.modalConInput {
  margin-top: 28px;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.modalConInput label {
  margin-bottom: 8px;
}
.modalConInput input {
  margin-bottom: 24px;
}
