.botonesContenedor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 22px;
  text-decoration: none;
}

.boton {
  width: fit-content;
  align-self: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.boton_secundario {
  color: #042F62;
  background: #FFFFFF;
  border: 1px solid #042F62;
}
.boton_secundario:hover {
  border: 1px solid #042F62;
}

.textoCargandoCaptura {
  color: #333333;
  padding: 0px 50px;
  text-align: center;
  margin: 0px;
}
