.contenedor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: 10px 0px;
}

.contenedorInformacion {
  width: 35%;
  margin: 0px;
}
.contenedorInformacion__titulo {
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  margin: 0px;
}
.contenedorInformacion .spinner {
  align-self: center;
}
.contenedorInformacion .datosUsuario {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contenedorInformacion .datosUsuario .datoPersonal {
  font-weight: bold;
  width: 50%;
}
.contenedorInformacion .datosUsuario .datoPersonal__rojo {
  color: #B60000;
  font-weight: bold;
  width: 50%;
}
.contenedorInformacion .datosUsuario .labelDatoPersonal {
  width: 50%;
}

.contenedorInformacion > div:first-child {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin-bottom: 40px;
}

.contenedorVideollamada {
  width: 60%;
  margin: 0px;
  display: flex;
}

.contenedorVideollamada > div:first-child {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.contenedorVideollamada > div:first-child .iframeVideollamada {
  height: 600px;
  width: 100%;
}

.botonesContenedor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 22px;
  text-decoration: none;
}

.boton {
  width: fit-content;
  align-self: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.boton_secundario {
  height: fit-content;
  color: #666666;
  background: #FFFFFF;
  border: 1px solid #666666;
}
.boton_secundario:hover {
  border: 1px solid #666666;
}
.boton_cancelar {
  height: fit-content;
  color: #B60000;
  background: #FFFFFF;
  border: 1px solid #B60000;
}
.boton_cancelar:hover {
  border: 1px solid #B60000;
}
