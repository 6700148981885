.contenedorInputBoton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputBusqueda {
  width: 660px;
  height: 42px;
  margin-top: 0px;
  margin-bottom: 14px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border-radius: 5px;
  color: #042F62;
  background-color: #FFFFFF;
  border: 1px solid #35435D !important;
  box-shadow: none;
}
.inputBusqueda:hover {
  cursor: pointer;
}
.inputBusqueda input {
  padding: 9px;
}
.inputBusqueda input:focus {
  padding: 7px;
  border-radius: 5px;
}

.boton {
  line-height: 14px;
}

.contenedorCiudadanos {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.paginado {
  align-self: center;
}

.spinner {
  display: flex;
  flex-direction: row;
  margin: 60px;
  justify-content: center;
}
