.error__container {
  height: 360px;
  width: 808px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -404px;
}

.error-imagen-container {
  float: right;
  width: 360px;
  height: 360px;
}

.error_img {
  width: 100%;
  height: 100%;
}

.error_text_container {
  max-width: 378px;
  position: absolute;
  bottom: 78px;
  text-align: left;
  float: left;
}
