.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.container p {
  margin: 0;
}

.tituloCard, .tituloCardSecundario {
  margin: 0;
  text-align: center;
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

.tituloCardSecundario {
  color: #042F62;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  margin: 48px 0px 0px;
  font-size: 20px;
  line-height: 24px;
}

.datosTurnos {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 32px 0px;
  background-color: #F9FBFC;
  border: 1px solid #003DA5;
  border-radius: 8px;
}
.datosTurnos .titulo {
  margin-bottom: 32px;
  font-size: 20px;
  line-height: 24px;
  color: #042F62;
}
.datosTurnos .bold {
  font-weight: 600;
  margin-left: 16px;
}

.datosTurnosBody {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
  padding: 30px;
}
.datosTurnosBody b {
  font-weight: 600;
}

.datosExtraTurnos {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.alinearDerecha, .alinearDerechaSecundario {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 16px;
  max-width: 256px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  color: #042F62;
}

.alinearDerechaSecundario {
  text-align: center;
  line-height: 36px;
  color: #020F28;
}

.alinearIzquierda, .alinearIzquierdaSecundario {
  display: flex;
  flex-direction: column;
  text-align: end;
  gap: 57px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: #042F62;
}

.alinearIzquierdaSecundario {
  gap: 16px;
  color: #020F28;
}

.aclaracion {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #020F28;
}

.error {
  font-size: 18px;
  color: #B60000;
}
