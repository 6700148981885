.container {
  display: flex;
  flex-direction: column;
}

.titulo {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  width: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  gap: 48px;
  margin: 48px 0px 0px;
}

.segundoParrafo {
  white-space: pre;
}
