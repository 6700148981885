.contenedorPrincipal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contenedorCampos {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.contenedorBotones {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.boton, .botonPrimario {
  padding: 8px 24px;
  height: 28px;
  min-width: 126px;
  border-radius: 4px;
  transition: 0.2s ease-out;
  font-size: 14px;
  font-weight: bold;
  line-height: 0px;
  text-align: center;
  border-style: solid;
  cursor: pointer;
}
.botonPrimario {
  color: #FFFFFF;
  background-color: #003DA5;
  border-color: #003DA5;
}
.botonPrimario:disabled {
  border: none;
}
.botonPrimario:hover {
  background-color: #042F62;
}
