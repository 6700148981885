.contenedorFechas {
  display: flex;
  align-items: center;
  gap: 14px;
  flex-direction: row;
  width: fit-content;
}

.botonFlecha {
  width: fit-content;
  height: fit-content;
  color: black;
  background-color: #F9F9F9;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
}
.botonFlecha img {
  width: 10px;
  height: 14px;
}
.botonFlecha:hover {
  cursor: pointer;
}

.contenedorHoyMes {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.contenedorMes {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.textoMes {
  color: #000000;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.contenedorDias {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.boxDia, .boxDiaSeleccionado {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  height: 150px;
  width: 200px;
  padding: 16px 10px;
  background-color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
}
.boxDiaSeleccionado {
  border: solid 1px #003DA5;
}
.boxDia .contenedorMetadatos, .boxDiaSeleccionado .contenedorMetadatos {
  display: flex;
  flex-direction: column;
  padding: 12px 24px 24px 24px;
}

.textoDia {
  color: #000000;
  width: 180px;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  border-bottom: solid 1px #EEEEEE;
  padding-bottom: 16px;
}

.textoMetadatos, .textoMetadatosDisabled {
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.textoMetadatosDisabled {
  color: #687286;
}

.botonHoy {
  height: 45px;
  padding: 10px 25px;
  background-color: #FFFFFF;
  align-self: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #003DA5;
}
