.acciones {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow-x: auto;
}
.acciones__tabla {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}
.acciones__tabla__striped tr:nth-child(2n) {
  background-color: #f8f8f8;
}
.acciones__tabla td, .acciones__tabla th {
  padding: 10px 16px;
  vertical-align: top;
  border: 1px solid #DDDDDD;
}
.acciones__tabla th {
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  vertical-align: bottom;
  background-color: #EEEEEE;
}
.acciones__tabla tr {
  background-color: #FFFFFF;
  border-top: 1px solid #bbb;
}
