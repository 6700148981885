.mainContenedor {
  display: flex;
  flex-direction: column;
  background-color: #F9F9F9;
  padding-top: 50px;
}

.card {
  align-self: center;
  min-width: 100% !important;
  margin: 40px 40px 0px 40px !important;
  padding: 0 !important;
  width: 100% !important;
  white-space: nowrap !important;
  overflow-x: scroll !important;
}

.textoAgentes {
  margin: 0 0 10px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 47px;
  color: #000000;
  align-self: center;
}

.textoInformativo {
  font-style: normal;
  align-self: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-top: 0;
}

.cardContenedor {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.paginationContenedor {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.noEncontrado {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}
