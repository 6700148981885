.modal {
  max-width: 480px;
  margin: 32px auto;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 40px 40px 48px;
}

/* Modal */
.modal-id-uy {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 90%;
  overflow: hidden;
  margin: 0 auto;
  padding: 32px 16px 48px;
  border-radius: 4px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 1024px) {
  .modal-id-uy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    max-width: 75%;
    padding: 40px 40px 56px;
  }
}

.modal-overlay-id-uy {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9;
}

.modal-close-button {
  opacity: 0.5;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #BBBBBB;
  line-height: 14px;
  font-size: 32px;
  border: 0;
  background-color: unset;
}

.modal-title {
  color: #333333;
  text-align: left;
}

.modal-content {
  color: #333333;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
}
@media screen and (min-width: 1024px) {
  .modal-content {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 24px;
  }
}
