.snackbar {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  bottom: 32px !important;
}

.snackbar > div {
  background-color: #666666 !important;
  color: #FFFFFF;
  border-radius: 4px !important;
  height: auto !important;
  padding: 8px !important;
}
@media screen and (min-width: 1024px) {
  .snackbar > div {
    padding: 24px !important;
  }
}

.snackbar > div > div {
  display: block !important;
  font-size: 12px !important;
  line-height: 20px !important;
}
@media screen and (min-width: 1024px) {
  .snackbar > div > div {
    font-size: 14px !important;
    line-height: 24px !important;
  }
}

.snackbar > div > div > span {
  display: block !important;
  text-align: center !important;
}
