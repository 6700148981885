.contenedor {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: center;
  width: 100%;
  justify-content: center;
}

.spinner {
  position: absolute;
  top: 150px;
}

.tabla {
  width: 100%;
  border-collapse: collapse;
  min-height: 400px;
}

.fila, .ultimaFilaDeBloque {
  height: 48px;
}
.fila td, .ultimaFilaDeBloque td {
  text-align: center;
  border-bottom: solid 1px #E0E0E0;
  border-radius: 4px;
}

.tdDeshabilitado {
  margin-bottom: 10px;
  background-color: #F9F9F9;
  border-radius: 4px;
  color: #687286;
}

.tdEstado, .tdEstadoInterrumpido, .tdEstadoPendienteCiudadano, .tdEstadoEnCurso, .tdEstadoExitoso, .tdEstadoRechazado, .tdEstadoPendienteAgente {
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-radius: 8px;
  height: 38px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.tdEstadoPendienteAgente {
  background-color: #FAF8EA;
  color: #000000;
}
.tdEstadoRechazado {
  color: #B60000;
}
.tdEstadoExitoso {
  color: #279E3F;
}
.tdEstadoPendienteCiudadano, .tdEstadoEnCurso {
  color: #687286;
}
.tdEstadoInterrumpido {
  color: #000000;
  border-radius: 8px;
  border: 1px solid #FBDADA;
}

.iconoInfo {
  width: 16px !important;
  height: 16px !important;
}

.ultimaFilaDeBloque {
  border-bottom: solid 1px #E0E0E0;
}

.primerFilaCelda, .primerFilaCeldaChica, .primerFilaCeldaVacia, .primerFilaCeldaGrande {
  margin-bottom: 1px;
  border-left: solid 4px #FFFFFF;
  height: 44px;
  padding: 10px;
  border-radius: 4px;
  background: #EEF2F7;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #031435;
}

.cabezal:first-child {
  border-left: solid 2px #FFFFFF;
}

.primerFilaCeldaGrande {
  width: 13%;
}

.primerFilaCeldaChica, .primerFilaCeldaVacia {
  width: 8%;
}

.primerFilaCeldaVacia {
  background-color: #FFFFFF;
}

.horaTurno {
  height: auto;
  background-color: #F9FBFC;
  border-left: solid 2px #FFFFFF;
  border-right: solid 4px #FFFFFF;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 45px;
  text-align: center;
  justify-content: center;
  color: #1C2C49;
}

.iconoCancelar, .iconoCancelarDisabled {
  margin-top: 10px !important;
  cursor: pointer !important;
}
.iconoCancelarDisabled {
  cursor: default !important;
  fill: #BBBBBB !important;
}

.botonLlamar {
  cursor: pointer;
  color: #042F62;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #042F62;
  padding: 5px;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.botonLlamar:hover {
  color: #042F62;
  background-color: #FFFFFF;
  border: 1px solid #042F62;
}
.botonLlamar:disabled {
  border-color: #687286;
  color: #687286;
  cursor: default;
}

.textoSinBoton {
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #687286;
}

.contenedorInfo {
  position: relative;
}

.hoverText {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.contenedorInfo:hover .hoverText {
  opacity: 1;
}
