.button-link {
  padding: 0 5px 5px 5px;
  display: block;
}
.button-link:focus {
  outline: #DAAA00 solid 2px;
  outline-offset: 1px;
}
.button-link-footer {
  text-decoration: none;
  vertical-align: middle;
  color: #DDDDDD;
  text-decoration: underline;
}
.button-link-footer:hover {
  text-decoration: none;
}
.button-link-header {
  text-decoration: underline;
  vertical-align: middle;
  font-weight: 600;
}
.button-link-header-dark {
  text-decoration: underline;
  color: #003da5;
  vertical-align: middle;
  font-weight: 400;
}
.button-link-default {
  color: #003da5;
}
.button-link-disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.button-link-disabled:hover {
  text-decoration: none;
}
.button-link-disabled:focus {
  text-decoration: none;
}
.button-link-dark {
  color: #000000;
  text-decoration: none;
  outline: none;
  cursor: auto;
}
.button-link-dark:hover {
  text-decoration: none;
}
.button-link-dark:focus {
  text-decoration: none;
}
