.headerNav {
  background-color: #FFFFFF;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  overflow: show;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .headerNav {
    background-color: transparent;
    position: relative;
    top: auto;
    left: auto;
    width: 600px;
    float: left;
    display: flex;
    gap: 25px;
    margin-top: 3px;
    justify-content: flex-end;
  }
}
.headerNav__item {
  height: 40px;
  width: 100%;
  line-height: 24px;
  border-bottom: 2px solid #EEEEEE;
  color: #003DA5;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
}
.headerNav__tooltip {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: #FFFFFF;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #BBBBBB;
  padding: 16px;
  z-index: 1;
}
.headerNav__tooltip__text {
  color: #003DA5;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
  background-color: unset;
  border: 0;
}

.headerNav span {
  display: flex;
  font-size: 14px;
  width: fit-content;
}

.headerNavTurnos {
  background-color: #FFFFFF;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  overflow: show;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .headerNavTurnos {
    background-color: transparent;
    position: relative;
    top: auto;
    left: auto;
    width: 600px;
    float: left;
    display: flex;
    gap: 25px;
    margin-top: 3px;
    justify-content: flex-end;
  }
}
.headerNavTurnos__tooltip {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 40px;
  right: 185px;
  background-color: #FFFFFF;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #BBBBBB;
  padding: 16px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.headerNavTurnos__tooltip__text {
  color: #003DA5;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
  background-color: unset;
  border: 0;
}

.headerNavTurnos span {
  display: flex;
  font-size: 14px;
  width: fit-content;
}

.headerNavVideollamada {
  background-color: #FFFFFF;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  overflow: show;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .headerNavVideollamada {
    background-color: transparent;
    position: relative;
    top: auto;
    left: auto;
    width: 600px;
    float: left;
    display: flex;
    gap: 25px;
    margin-top: 3px;
    justify-content: flex-end;
  }
}
.headerNavVideollamada__tooltip {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 40px;
  right: 32px;
  background-color: #FFFFFF;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #BBBBBB;
  padding: 16px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.headerNavVideollamada__tooltip__text {
  color: #003DA5;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
  background-color: unset;
  border: 0;
}

.headerNavVideollamada span {
  display: flex;
  font-size: 14px;
  width: fit-content;
}

.headerInterno {
  float: right;
  position: inherit;
}
@media screen and (min-width: 1024px) {
  .headerInterno {
    display: flex;
    gap: 10px;
    position: relative;
  }
}

.botonLink {
  padding: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 160px;
}
