.contenedor {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 90%;
  justify-content: center;
  padding-bottom: 20px;
}

.spinner {
  position: absolute;
  top: 150px;
}

.tabla {
  width: 100%;
  border-collapse: collapse;
  min-height: 200px;
}

.fila {
  height: 48px;
  border-bottom: solid 1px #E0E0E0;
}
.fila td {
  text-align: center;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 200px;
  padding: 0 6px 0 6px;
}

.primerFilaCelda, .primerFilaPermisos, .primerFilaCeldaChica, .primerFilaCeldaGrande {
  margin-bottom: 1px;
  border-left: solid 4px #FFFFFF;
  height: 76px;
  padding: 10px;
  border-radius: 4px;
  background: #EEF2F7;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #031435;
}

.cabezal {
  border-bottom: solid 1px #E0E0E0;
}

.cabezal:first-child {
  border-left: solid 2px #FFFFFF;
}

.primerFilaCeldaGrande {
  min-width: 150px;
}

.primerFilaPermisos {
  white-space: nowrap;
  min-width: 95px;
  background-color: #E2E9F1;
}

.ciDatos {
  height: auto;
  background-color: #F9FBFC;
  border-left: solid 2px #FFFFFF;
  border-right: solid 4px #FFFFFF;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 45px;
  text-align: center;
  justify-content: center;
  color: #1C2C49;
}

.iconoEditar {
  cursor: pointer;
}
