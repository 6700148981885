.contenedorPrincipal {
  display: flex;
  flex-direction: column;
}
.contenedorPrincipalSinTexto {
  margin-top: 38px;
}

.texto {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #042F62;
}

.contenedorInputSelector {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input {
  width: 200px;
  height: 56px;
  margin-top: 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
  border: 1px solid #042F62;
  color: #687286;
  background-color: #FFFFFF;
}
.input:hover {
  cursor: pointer;
}

.calendario {
  margin-top: 24px;
  align-self: center;
}

.menuDown {
  margin-left: 115px;
}

.menuDownNoValue {
  margin-left: 155px;
}
