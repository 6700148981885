// ------------------------------------------------------------------------------------------------------------------
// Font
// ------------------------------------------------------------------------------------------------------------------
$font: 'Open Sans', sans-serif;

// ------------------------------------------------------------------------
// Responsive breakpoints
// ------------------------------------------------------------------------
$desktop-min-width: 1024px;
$small-desktop-min-width: 992px;
$tablet-min-width: 768px;
$render-field-expanded-label-width: 200px;

// ------------------------------------------------------------------------
// Colors
// ------------------------------------------------------------------------
$primary: #0060a3;
$primary-dark: #004270;
$primary-light: #5696FF;
$primary-light-1: #cadaf6;
$primary-disabled: #9AA1AE;
$info: #E9F2FF;
$info-texto: #4A5568;
$warning: #FAF8EA;
$warning-2: #FBDADA;
$secondary: #FCD649;
$secondary-dark: #FCC526;
$secondary-button: #daaa00;
$secondary-button-light: #fcce26;
$secondary-light: #FFF6DC;
$error: #B60000;
$error-light: #FFE5E5;
$error-dark: #a31818;
$success: #279E3F;
$success-light: #E8FFEC;
$grey-1: #222222;
$grey-2: #333333;
$grey-3: #666666;
$grey-4: #BBBBBB;
$grey-5: #DDDDDD;
$grey-6: #EEEEEE;
$grey-7: #F3F3F3;
$grey-8: #F9F9F9;
$grey-9: #EEF2F7;
$grey-10: #E0E0E0;
$grey-11: #F9FBFC;
$grey-12: #9AA1AE;
$grey-13: #e6e6e6;
$grey-14: #E2E9F1;
$grey-15: #f8f8f8;
$white: #FFFFFF;
$black: #000000;
$green: #00632e;
$green-2: #e8ffec;

// ------------------------------------------------------------------------
// Colors - gub.uy
// ------------------------------------------------------------------------
$blue: #003DA5;
$blue-1: #687286;
$blue-2: #35435D;
$dark-blue: #042F62;
$dark-blue-1: #031435;
$dark-blue-2: #1C2C49;
$dark-blue-3: #020F28;
$gold: #DAAA00;
$dark-grey: #4C4D4B;

// ------------------------------------------------------------------------
// Alerts Videollamada
// ------------------------------------------------------------------------
$rechazado-border-color: #ffd15f;
$rechazado-background-color: #fbf8ea;
$aceptado-border-color: #2d5ca8;
$aceptado-background-color: #eaf2ff;
$error-border-color: #bbb;
$error-background-color: #f5f5f5;
$advertencia-background-color: #fbf8ea;
$advertencia-border-color: #ffd15f;
$fuente-color: #052d0d;
$info-background-color: #F9FBFC;

// ------------------------------------------------------------------------
// Border radius
// ------------------------------------------------------------------------
$border-radius: 4px;

// ------------------------------------------------------------------------
// Spacing
// ------------------------------------------------------------------------
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-56: 56px;
$spacing-64: 64px;

// ------------------------------------------------------------------------
// Sizes
// ------------------------------------------------------------------------
$min-width: 960px;

$content-max-width: 960px;
$content-width-phone: 90%;
$content-width-tablet: 75%;
$content-width-desktop: 75%;

// ------------------------------------------------------------------------
// Nav
// ------------------------------------------------------------------------
$nav-max-width: 1280px;
$nav-height: 48px;
$nav-item-padding: 12px 24px;

$header-height-phone: 50px;
$header-height-desktop: 90px;

// ------------------------------------------------------------------------
// Footer (pp)
// ------------------------------------------------------------------------
$footer-height-phone: 542px;
$footer-height-tablet: 204px;
$footer-height-desktop: 144px;

$footer-width-tablet: 522px;
$footer-width-desktop: $content-max-width;

$footer-line-height: 54px;

// ------------------------------------------------------------------------
// Chatbot
// ------------------------------------------------------------------------
$chatbot-height: 50px;

// ------------------------------------------------------------------------
// Transitions
// ------------------------------------------------------------------------
$transitionDefault: 0.3s all ease-out;
