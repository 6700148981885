.contenedorNumeroTelefono {
  margin-bottom: 30px;
}
.contenedorNumeroTelefono input {
  width: 328px !important;
  height: 56px;
  border-radius: 4px;
  padding: 17px 0 17px 16px;
  border-color: #042F62 !important;
  background: #FFFFFF;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #031435;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .contenedorNumeroTelefono input {
    width: 360px !important;
  }
}

.contenedorBotones {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 24px;
}

.botonSecundario {
  background-color: #EEEEEE;
  color: #042F62;
}
.botonSecundario:hover {
  background-color: #DDDDDD;
}

.textoError {
  width: 325px;
  height: 42px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #B60000;
}

.help_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  color: #000000;
  font-size: 15px;
  line-height: 22px;
}
