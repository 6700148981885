.buttonSolid, .buttonSolid__small {
  padding: 8px 24px;
  width: fit-content;
  min-width: 123px;
  line-height: 24px;
  border: none;
  color: #FFFFFF;
  background-color: #003DA5;
  border-radius: 4px;
  font-weight: 600;
  transition: box-shadow 0.35s, background-color 0.35s;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
.buttonSolid:hover, .buttonSolid__small:hover {
  background-color: #284586;
}
.buttonSolid:focus, .buttonSolid__small:focus {
  outline: 2px solid #DAAA00;
  outline-offset: 1px;
}
.buttonSolid:active, .buttonSolid__small:active {
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.25);
}
.buttonSolid.__danger, .__danger.buttonSolid__small {
  background-color: #B60000;
}
.buttonSolid.__danger:hover, .__danger.buttonSolid__small:hover {
  background-color: #a31818;
}
.buttonSolid__registro-form--disabled {
  background-color: #9AA1AE;
  width: 360px;
  height: 45px;
  padding: 10px 25px;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .buttonSolid__registro-form--disabled {
    width: 328px;
  }
}
.buttonSolid__registro-form--enabled {
  background-color: #003DA5;
  width: 360px;
  height: 45px;
  padding: 10px 25px;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .buttonSolid__registro-form--enabled {
    width: 328px;
  }
}

.secundario {
  background-color: #daaa00;
}
.secundario span {
  color: black;
}

.secundario:hover {
  background-color: #fcce26;
}

.buttonSolid:disabled, .buttonSolid__small:disabled {
  background-color: #9AA1AE;
  pointer-events: none;
}

.buttonSolid__small {
  padding: 8px 12px;
  height: 32px;
  line-height: 16px;
  font-size: 14px;
}
