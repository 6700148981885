.button-icon {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
}
.button-icon:hover {
  background-color: #F3F3F3;
}
.button-icon:active {
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.25);
}
.button-icon__img {
  position: absolute;
  transform: translate(-50%, -50%);
}
