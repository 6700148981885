.btn {
  height: 28px;
  min-width: 72px;
  border-radius: 4px;
  background-color: #F3F3F3;
  border-color: #F3F3F3;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  color: #222222;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
  border-style: solid;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  transition: 0.2s;
}
.btn:hover {
  background-color: #F9F9F9;
  border-color: #F9F9F9;
}
.btn:active {
  background-color: #F9F9F9;
  border-color: #F9F9F9;
  box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.1);
}
.btn-right {
  float: right;
}
.btn-left {
  float: left;
}
.btn-center {
  display: block;
  margin: 0 auto;
}
.btn-sm {
  min-width: 72px;
}
.btn-md {
  min-width: 126px;
}
.btn-80 {
  min-width: 80px;
}
.btn-lg {
  height: 40px;
  min-width: 96px;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 24px;
}
.btn__search {
  border-radius: 0px 4px 4px 0px;
  height: 40px !important;
  width: 40px !important;
  min-width: 0px;
}
.btn:focus-visible {
  outline: #DAAA00 solid 2px;
  outline-offset: 1px;
}

.btn-primary {
  background-color: #003DA5;
  border-color: #003DA5;
  color: #FFFFFF;
}

.btn-primary:hover {
  background-color: #042F62;
  border-color: #042F62;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
}

.btn-primary:active {
  background-color: #042F62;
  border-color: #042F62;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
}

.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: #9AA1AE;
  border-color: #9AA1AE;
  box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.full-size {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}

.is-last {
  margin-bottom: 0 !important;
}

.is-first {
  margin-top: 32px !important;
}
