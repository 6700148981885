.headerContainer {
  background-color: #FFFFFF;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 2px solid #EEEEEE;
}
@media screen and (min-width: 1024px) {
  .headerContainer {
    height: 90px;
  }
}

.headerWrapper {
  padding: 10px 16px 12px;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  .headerWrapper {
    width: 960px;
    height: 90px;
    padding: 25px 0;
    max-width: 960px;
    margin: 0 auto;
  }
}
.headerWrapper__left {
  width: 50%;
  height: 100%;
  float: left;
  display: flex;
}
.headerWrapper__left button {
  padding: 0;
  border: none;
  background: none;
}
.headerWrapper__left__logo {
  height: auto;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  font-size: 22px;
  color: #003DA5;
}
.headerWrapper__left__lineSeparator {
  box-sizing: border-box;
  height: 20px;
  width: 1px;
  border: 1px solid #DDDDDD;
  align-items: center;
  margin: 4px 16px;
  float: left;
}
@media screen and (min-width: 1024px) {
  .headerWrapper__left__lineSeparator {
    height: 24px;
    margin: 8px 16px;
  }
}
.headerWrapper__left__text {
  height: 40px;
  align-items: center;
  line-height: 28px;
  font-family: "Open Sans", sans-serif;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 4px 0;
  float: left;
}
@media screen and (min-width: 1024px) {
  .headerWrapper__left__text {
    padding: 8px 0;
  }
}
.headerWrapper__right {
  width: 50%;
  height: 100%;
  float: right;
}
.headerWrapper__right__agenteNombre {
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 28px;
  height: 40px;
  width: auto;
  color: #4C4D4B;
  padding: 4px 24px;
  float: right;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
@media screen and (min-width: 1024px) {
  .headerWrapper__right__agenteNombre {
    font-size: 14px;
    padding: 8px 24px;
  }
}

.focusStyle:focus-visible {
  outline-offset: 1px;
  outline: #DAAA00 2px solid;
}
